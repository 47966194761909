import React from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Stack, Typography, useTheme, Card, CardContent } from "@mui/material";
import { Button, FormSelect, Input } from "@sbm/ui-components";
import { AddressTypeEnum, IOrganization } from "@types";

interface AddressesProps {
  register: UseFormRegister<IOrganization>;
  setValue: UseFormSetValue<IOrganization>;
  control: Control<IOrganization>;
  errors: FieldErrors<IOrganization>;
}

export const Addresses: React.FC<AddressesProps> = ({
  control,
  setValue,
  register,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation("common", {
    keyPrefix: "create_organization.contacts_section",
  });

  const { address = [] } = useWatch({ control });

  const addressTypeOptions = React.useMemo(() => {
    return Object.values(AddressTypeEnum).map((item) => ({
      option: t(item),
      value: item,
    }));
  }, [t]);

  const handleAdd = () => {
    const dataToSet = [
      ...address,
      { fullAddress: "" },
    ] as IOrganization["address"];
    setValue("address", dataToSet, { shouldDirty: true });
  };

  const handleDelete = (position: number) => {
    const newAddress = (address?.filter((_, index) => index !== position) ||
      []) as IOrganization["address"];

    setValue("address", newAddress, {
      shouldDirty: true,
    });
  };

  return (
    <Stack gap={6}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h8" fontWeight={700}>
          {t("addresses_section_title")}
        </Typography>

        <Button
          size="large"
          color="secondary"
          variant="text"
          onClick={handleAdd}
          startIcon={
            <Icon name="Plus" size={18} color={palette.secondary.main} />
          }
        >
          {t("add_button_label")}
        </Button>
      </Stack>

      <>
        {address.map((_, index) => {
          return (
            <Card key={index}>
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: 4 }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("addresses_section_subtitle", { number: index + 1 })}
                  </Typography>

                  {index > 0 && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="text"
                      onClick={() => handleDelete(index)}
                      startIcon={
                        <Icon
                          name="Trash2"
                          size={18}
                          color={palette.error.main}
                        />
                      }
                    >
                      {t("remove_button_label")}
                    </Button>
                  )}
                </Stack>

                <FormSelect
                  name={`address.${index}.addressType`}
                  control={control}
                  label={t("address_type_input_label")}
                  values={addressTypeOptions}
                />

                <Input
                  label={t("address_input_label")}
                  maxLength={1000}
                  {...register(`address.${index}.fullAddress`)}
                />
              </CardContent>
            </Card>
          );
        })}
      </>
    </Stack>
  );
};
