import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "app";

import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { InfiniteData } from "@tanstack/react-query";
import { IMeta, ISupportTask, ITask, TaskCategoryEnum } from "@types";

interface MyTaskFiltersProps {
  data:
    | InfiniteData<{ items: ITask[] | ISupportTask[]; meta: IMeta }>
    | undefined;
}

export const TaskFilters: React.FC<MyTaskFiltersProps> = ({ data }) => {
  const { t } = useTranslation("tasks");
  const { palette } = useTheme();
  const { get, remove, append, set } = useQueryParams();

  const meta = data?.pages[0]?.meta;
  const inTheSpotlightCount = meta?.inTheSpotlightCount;
  const completedCount = meta?.completedCount;
  const requiresActionCount = meta?.requiresActionCount;

  const getCount = (count: number | undefined, color: string) => {
    if (typeof count !== "number") return;
    return (
      <Typography fontSize={13} fontWeight={700} color={color}>
        {count >= 100 ? "99+" : count}
      </Typography>
    );
  };

  const categoriesFiltersByUrl = (get("filter", true) as string[]) || [];

  const groupFilters = categoriesFiltersByUrl.filter((cf) =>
    cf.includes("group=")
  );
  const groupType = groupFilters.flatMap((g) => {
    const filter = g.split("=");
    return filter[1].split(",");
  });

  const handleSelect = (
    _: React.MouseEvent<HTMLElement>,
    values: TaskCategoryEnum[]
  ) => {
    if (values.length > 0) {
      remove("filter");
      const str = values.join(",");
      append("filter", `group=${str}`);
    } else {
      set("filter", "group=null");
    }
  };

  const defaultGroup = ["requires_action", "in_the_spotlight", "completed"];

  return (
    <Stack flexDirection="row" alignItems="center" mr={4}>
      <ToggleButtonGroup
        color="secondary"
        size="medium"
        value={groupType.length === 0 ? defaultGroup : groupType}
        onChange={handleSelect}
        sx={{ height: "40px" }}
      >
        <ToggleButton value={TaskCategoryEnum.requiresAction}>
          <Tooltip
            arrow
            placement="top"
            title={t(`categories.${TaskCategoryEnum.requiresAction}`)}
          >
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={45}
              gap={1}
            >
              <Stack
                width={25}
                height={12}
                bgcolor={palette.secondary.main}
                borderRadius={1}
              />
              {getCount(requiresActionCount, palette.secondary.main)}
            </Stack>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={TaskCategoryEnum.inTheSpotlight}>
          <Tooltip
            arrow
            placement="top"
            title={t(`categories.${TaskCategoryEnum.inTheSpotlight}`)}
          >
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={45}
              gap={1}
            >
              <Stack
                width={25}
                height={12}
                bgcolor="#6173A0"
                borderRadius={1}
              />
              {getCount(inTheSpotlightCount, "#6173A0")}
            </Stack>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={TaskCategoryEnum.completed}>
          <Tooltip
            arrow
            placement="top"
            title={t(`categories.${TaskCategoryEnum.completed}`)}
          >
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={45}
              gap={1}
            >
              <Stack
                width={25}
                height={12}
                bgcolor="#E4E6E8"
                borderRadius={1}
              />
              {getCount(completedCount, "#B3B6B9")}
            </Stack>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
