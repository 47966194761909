import React from "react";

import en from "date-fns/locale/en-US";
import ru from "date-fns/locale/ru";

import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS, ruRU } from "@mui/x-date-pickers/locales";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";

export interface TimePickerProps extends MuiTimePickerProps<any> {
  onDateChange?: (arg: Date) => void;
  date?: Date | null;
  label: string;
}

export const TimePicker = (props: TimePickerProps) => {
  const locale = getStorageItem(STORAGE_KEYS.lang);
  const isEnglish = locale === "en";

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={isEnglish ? en : ru}
      localeText={
        isEnglish
          ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
          : ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MuiTimePicker
        label={props.label}
        minutesStep={30}
        ampm={false}
        value={props.date}
        onChange={(value, context) => {
          if (value && props.onDateChange) {
            props.onDateChange(value);
          }
        }}
      />
    </LocalizationProvider>
  );
};
