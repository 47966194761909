import React from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { IOrganization } from "@types";

import { PhoneNumbers, Emails, Addresses } from "./components";

export interface OrganizationContactsFormProps {
  register: UseFormRegister<IOrganization>;
  control: Control<IOrganization>;
  setValue: UseFormSetValue<IOrganization>;
  errors: FieldErrors<IOrganization>;
}

export const OrganizationContactsForm: React.FC<
  OrganizationContactsFormProps
> = (props) => {
  const { t } = useTranslation("common", {
    keyPrefix: "create_organization.contacts_section",
  });
  return (
    <Stack gap={6}>
      <Input
        label={t("website_input_label")}
        maxLength={200}
        error={Boolean(props.errors.website)}
        {...props.register("website", { pattern: /^[a-zA-Z]+$/ })}
      />

      <PhoneNumbers {...props} />
      <Emails {...props} />
      <Addresses {...props} />
    </Stack>
  );
};
