import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchWorkingDays } from "api";
import { useDebounce } from "app";
import { TimePicker, DatePicker } from "ui-kit";

import { Grid } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { TExecutionRequirement, TWorkingDaysEditMode } from "@types";

export interface IWorkingDateData {
  date: string;
  workingDays: number;
  calendarDays: number;
}
interface Props {
  scheduledDateOfExecution: Date | string | undefined;
  workingDaysForExecution: number;
  calendarDaysForExecution?: number;
  onChange: (res: IWorkingDateData) => void;
  requirements?: TExecutionRequirement;
  editMode?: TWorkingDaysEditMode;
  isResolutionForTheDocument?: boolean;
}

let cachedRes: null | IWorkingDateData = null;

export const WorkingDay = (props: Props) => {
  const {
    scheduledDateOfExecution,
    workingDaysForExecution,
    calendarDaysForExecution,
    onChange,
    requirements,
    editMode,
    isResolutionForTheDocument,
  } = props;

  const [date, setDate] = useState(
    scheduledDateOfExecution ? new Date(scheduledDateOfExecution) : undefined
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [workingDays, setWorkingDays] = useState<string>(
    workingDaysForExecution?.toString()
  );
  const [calendarDays, setCalendarDays] = useState<string>(
    calendarDaysForExecution ? calendarDaysForExecution?.toString() : ""
  );

  const { t } = useTranslation("tasks");

  const debouncedWorkingDays = useDebounce(workingDays, 1000);
  const debouncedCalendarDays = useDebounce(calendarDays, 1000);

  useEffect(() => {
    if (!cachedRes || !debouncedCalendarDays || isFirstRender) {
      return;
    }
    if (cachedRes.calendarDays !== Number(debouncedCalendarDays)) {
      fetchWorkingDays({
        calendarDays: +debouncedCalendarDays,
      }).then((result) => {
        cachedRes = result;
        onChange(result);
        setWorkingDays(result.workingDays);
        setDate(new Date(result.date));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCalendarDays]);

  useEffect(() => {
    if (!cachedRes || !debouncedWorkingDays || isFirstRender) {
      return;
    }

    if (cachedRes.workingDays !== Number(debouncedWorkingDays)) {
      fetchWorkingDays({
        workingDays: +debouncedWorkingDays,
      }).then((result) => {
        cachedRes = result;
        setCalendarDays(result.calendarDays);
        setDate(new Date(result.date));
        onChange(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWorkingDays]);

  useLayoutEffect(() => {
    setIsFirstRender(false);
    if (
      calendarDaysForExecution &&
      workingDaysForExecution &&
      scheduledDateOfExecution
    ) {
      return;
    }
    const fetchData = async () => {
      const res = await fetchWorkingDays({
        workingDays: workingDaysForExecution,
      });

      cachedRes = res;

      setCalendarDays(res.calendarDays);
      setDate(new Date(res.date));
      onChange(res);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChanged = async (newDate: Date) => {
    setDate(newDate);
    const res = await fetchWorkingDays({
      date: newDate as unknown as string,
    });

    cachedRes = res;

    setCalendarDays(res.calendarDays);
    setWorkingDays(res.workingDays);
    onChange(res);
  };

  return (
    <Grid container my={6} columns={20} spacing={2}>
      <Grid item xs={7}>
        <DatePicker
          label={t("scheduled_date")}
          variant="outlined"
          size="medium"
          disablePast
          onDateChange={handleDateChanged}
          disabled={editMode?.scheduledDateDisableState}
          value={date}
          maxDate={null}
        />
      </Grid>
      <Grid item xs={5}>
        <TimePicker
          date={date}
          onDateChange={handleDateChanged}
          label={t("time")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("working_days")}
          variant="outlined"
          type="number"
          defaultValue={workingDaysForExecution}
          disabled={editMode?.workingDaysDisableState}
          required
          size="medium"
          value={workingDays}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setWorkingDays(e.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("calendar_days")}
          variant="outlined"
          type="number"
          disabled={editMode?.calendarDaysDisableState}
          required
          value={calendarDays}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setCalendarDays(e.target.value);
            }
          }}
          size="medium"
        />
      </Grid>
    </Grid>
  );
};
